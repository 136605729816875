import React from 'react'
import Layout from '../../components/layout/layout'
import { Columns, Container, Section, Form as BulmaForm } from 'react-bulma-components'
import SelectField from '../../components/form/fields/select'
import { Field, Form, Formik } from 'formik'
import InputField from '../../components/form/fields/input'
import MultiSelectField from '../../components/form/fields/multi-select'
import AutocompleteField from '../../components/form/fields/autocomplete'

export const DevForms = () => {
  return (
    <>
      <Layout>
        <Section>
          <Container>
            <Formik
              initialValues={{ value: 'value', empty: '', multiValue: ['value'] }}
              onSubmit={() => {
                return
              }}
            >
              <Form>
                <Columns>
                  <Columns.Column narrow>
                    <BulmaForm.Field>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="" />
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                    <BulmaForm.Field>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Empty" />
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                    <BulmaForm.Field>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Value" />
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                    <BulmaForm.Field>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Required + Empty" />
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                    <BulmaForm.Field>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Required + Valid" />
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                  </Columns.Column>
                  <Columns.Column narrow>
                    <BulmaForm.Field>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Text" />
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                    <Field component={InputField} value="" />
                    <Field component={InputField} value="value" />
                    <Field component={InputField} required value="" />
                    <Field component={InputField} required value="value" />
                  </Columns.Column>
                  <Columns.Column narrow>
                    <BulmaForm.Field>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="Select" />
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                    <Field
                      component={SelectField}
                      name="empty"
                      options={[{ value: 'something', label: 'something' }]}
                    />
                    <Field
                      component={SelectField}
                      name="value"
                      options={[{ value: 'value', label: 'value' }]}
                    />
                    <Field component={SelectField} options={[]} name="empty" required />
                    <Field
                      component={SelectField}
                      options={[{ value: 'value', label: 'value' }]}
                      name="value"
                    />
                  </Columns.Column>
                  <Columns.Column narrow>
                    <BulmaForm.Field>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="MultiSelect" />
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                    <Field
                      component={MultiSelectField}
                      name="empty"
                      value={undefined}
                      options={[{ value: 'value', label: 'value' }]}
                    />
                    <Field
                      component={MultiSelectField}
                      name="multiValue"
                      value={undefined}
                      options={[{ value: 'value', label: 'value' }]}
                    />
                    <Field
                      component={MultiSelectField}
                      name="empty"
                      required
                      value={undefined}
                      options={[{ value: 'value', label: 'value' }]}
                    />
                    <Field
                      component={MultiSelectField}
                      name="multiValue"
                      required
                      options={[{ value: 'value', label: 'value' }]}
                    />
                  </Columns.Column>
                  <Columns.Column narrow>
                    <BulmaForm.Field>
                      <BulmaForm.Control>
                        <BulmaForm.Input disabled value="AutoComplete" />
                      </BulmaForm.Control>
                    </BulmaForm.Field>
                    <Field
                      component={AutocompleteField}
                      name="empty"
                      items={[{ value: 'value', label: 'value' }]}
                    />
                    <Field
                      component={AutocompleteField}
                      name="value"
                      items={[{ value: 'value', label: 'value' }]}
                    />
                    <Field
                      component={AutocompleteField}
                      name="empty"
                      required
                      items={[{ value: 'value', label: 'value' }]}
                    />
                    <Field
                      component={AutocompleteField}
                      name="value"
                      required
                      items={[{ value: 'value', label: 'value' }]}
                    />
                  </Columns.Column>
                </Columns>
              </Form>
            </Formik>
          </Container>
        </Section>
      </Layout>
    </>
  )
}
